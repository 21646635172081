import React, { useState, useEffect } from "react";
import { surveyData, surveyDataSpanish } from "./surveyData";
import "./survey.css";
import ResultChart from "./resultChart/resultChart";
import Logo from "../../assets/eatSharpLogo.png";
import LogoSpanish from "../../assets/logoSpanish.png";
import { addDoc, collection } from "firebase/firestore";
import db from "../../firebase";
import Ques21 from "./ques21/ques21";
import ChooseLanguage from "../molecules/chooseLanguage/chooseLanguage";
import LangIcon from "../molecules/langIcon/langIcon";
import { Localization } from "../../config/localization";
import { useSearchParams } from "react-router-dom";

const Survey = () => {
  const [submittedData, setSubmittedData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [langCode, setLangCode] = useState();
  const [validation, setValidation] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [originalVals, setOriginalVals] = useState([
    10, -4, 6, 6, 6, 10, 4, 4, -10, 12, 6, 4, 8, 4, 6, -4, 8, -8, -4, 6,
  ]);
  const veganQues = Localization.consumeWalnuts;
  // "Consume, for omega-3, any algae, avocado, Brussels sprouts, chia seed, edamame, flaxseed, hemp seed, kidney beans, navy beans, seaweed, spinach, and walnuts,";
  const vegeQues = Localization.consumeWalnuts;
  // "Consume, for omega-3, any algae, avocado, Brussels sprouts, chia seed, edamame, flaxseed, hemp seed, kidney beans, navy beans, seaweed, spinach, and walnuts,";
  const nonVeganQues = Localization.consumeTrout;
  // "Consume, for omega-3, any fatty fish, including anchovies, bluefin tuna, herring, mackerel, salmon, sardines, striped bass, or rainbow trout? ";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  let d = new Date();
  let currDate = d.getDate();
  let currMonth = d.getMonth() + 1;
  let currYear = d.getFullYear();
  let dateToday =
    currYear +
    "-" +
    (currMonth < 10 ? "0" + currMonth : currMonth) +
    "-" +
    (currDate < 10 ? "0" + currDate : currDate);
  const [date, setDate] = useState(dateToday);
  const [age, setAge] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isVegan, setIsVegan] = useState(null);
  const [isVegetarian, setIsVegetarian] = useState(null);
  const [smartPoints, setSmartPoints] = useState(0);
  const [riskPoints, setRiskPoints] = useState(0);
  const [missedPoints, setMissedPoints] = useState(0);
  const [opportunityPoints, setOpportunityPoints] = useState(0);
  const [result, setResult] = useState("");
  const [missedQstate, setMissedQstate] = useState([]);
  const [riskQstate, setRiskQstate] = useState([]);
  const [smartQstate, setSmartQstate] = useState([]);
  const [localSurveyData, setLocalSurveyData] = useState();
  const [addedSugarText, setAddedSugarText] = useState("");
  const [localLang, setLocalLang] = useState(null);
  const [showLangPopup, setShowLangPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [group, setGroup] = useState(searchParams.get("group"));
  const [ques22Answer, setQues22Answer] = useState(null);

  useEffect(() => {
    // window.localStorage.setItem("lang","English")
    const lang = window.localStorage.getItem("lang");

    setLangCode(lang);
    if (lang) {
      setLocalLang(lang);
    } else {
      setShowLangPopup(true);
    }
    Localization.setLanguage(lang === "Spanish" ? "es" : "en");
    setLocalSurveyData(
      lang && lang === "Spanish" ? surveyDataSpanish : surveyData
    );
  }, []);

  const data = () => {
    let d = Date.now();
  };
  const onRadioChange = (e) => {
    submittedData[[e.target.name] - 1] = Number(e.target.value);
    validation[[e.target.name] - 1] = 1;
    checkValid();
  };
  const setNameFunc = (e) => {
    setName(e.target.value);
    checkValid();
  };
  const setEmailFunc = (e) => {
    setEmail(e.target.value);
    checkValid();
  };
  const setDateFunc = (e) => {
    setDate(e.target.value);
    checkValid();
  };
  const setQues22AnswerFunc = (e) => {
    setQues22Answer(e.target.value);
    checkValid();
  };
  const checkValid = () => {
    let val = validation.filter((item) => item === 0);

    // console.log(isVegan);
    if (
      val.length > 0 ||
      date === "" ||
      isVegan === null ||
      isVegetarian === null
    ) {
      return false;
    } else {
      setDisabled(false);
      return true;
    }
  };

  const calculateData = (e) => {
    e.preventDefault();
    let val = validation.filter((item) => item === 0);

    if (!checkValid) return;

    const missed = submittedData
      .map((item, index) => {
        if (item === 0) return index;
      })
      .filter((a) => a !== undefined);

    let missedSum = 0;
    let missedQ = [];
    if (missed.length > 0) {
      missed.map((item) => {
        if (originalVals[item] > 0) {
          missedQ.push(item + 1);
          missedSum = missedSum + originalVals[item];
        }
      });
    }

    setMissedQstate(missedQ);

    let smartSum = 0;
    let riskQ = submittedData.map((item, index) => {
      smartSum = smartSum + item;
      if (item < 0) {
        return index + 1;
      }
    });
    setRiskQstate(riskQ);

    let smartQ = submittedData.map((item, index) => {
      if (item > 0) {
        return index + 1;
      }
    });
    setSmartQstate(smartQ);

    const risk = submittedData.filter((item) => item < 0);
    let riskSum = 0;
    if (risk.length > 0) {
      riskSum = risk.reduce((a, b) => a + b);
    }
    setSmartPoints(smartSum < 0 ? 0 : smartSum);
    setRiskPoints(Math.abs(riskSum));
    setMissedPoints(missedSum);
    const opportunity = missedSum + Math.abs(riskSum);

    setOpportunityPoints(opportunity);

    calculateResult(smartSum);
    if (String(name).toLowerCase() === "test") {
      return;
    } else {
      addtoFirebase(smartSum, Math.abs(riskSum), missedSum);
    }
  };

  const addtoFirebase = async (smartSum, riskSum, missedSum) => {
    const lang = window.localStorage.getItem("lang");
    try {
      const docRef = await addDoc(collection(db, "EmailList"), {
        email: email ? email : "None",
        name: name ? name : "None",
        group: group ? group : "",
        smartPoints: smartSum,
        riskPoints: riskSum,
        missedPoints: missedSum,
        isVegan,
        isVegetarian,
        date: Date(date).substring(4, 15),
        submittedData,
        lang: lang ? lang : null,
        redMeatConsumption: ques22Answer,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const calculateResult = (value) => {
    if (value >= 97 && value <= 100) {
      setResult(Localization.great90);
    } else if (value >= 81 && value <= 96) {
      setResult(Localization.between81and90);
    } else if (value >= 61 && value <= 80) {
      setResult(Localization.between61and80);
    } else if (value >= 40 && value <= 60) {
      setResult(Localization.between40and60);
    } else if (value < 40) {
      setResult(Localization.lessThan40);
    }

    setShowResult(true);
  };
  const veganFunc = (e) => {
    let val = e.target.value;
    let updatedVal = localSurveyData;

    switch (val) {
      case "Yes":
        setIsVegan(true);
        updatedVal = updatedData(veganQues);
        break;

      case "No":
        setIsVegan(false);
        if (isVegetarian) {
          updatedVal = updatedData(vegeQues);
          return;
        } else {
          updatedVal = updatedData(nonVeganQues);
        }
        break;

      case "vegeYes":
        setIsVegetarian(true);
        updatedVal = updatedData(vegeQues);
        break;

      case "vegeNo":
        setIsVegetarian(false);
        if (isVegan) {
          updatedVal = updatedData(veganQues);
          return;
        } else {
          updatedVal = updatedData(nonVeganQues);
        }

        break;

      default:
        setIsVegetarian(false);
        setIsVegan(false);
        updatedVal = updatedData(nonVeganQues);

        break;
    }
    checkValid();
    setLocalSurveyData(updatedVal);
  };

  const updatedData = (ques) => {
    let data;
    if (Localization.getLanguage() === "en") {
      data = surveyData;
    } else {
      data = surveyDataSpanish;
    }
    let updatedVal = data.map((item, index) => {
      if (index === 9) {
        return {
          ...item,
          title: ques,
        };
      } else {
        return item;
      }
    });

    return updatedVal;
  };

  const calcQues21Result = (val) => {

    let text = '';
    if (val == -1) {
      text = Localization.quest21DoNotKnow;
    }
    else if (val <= 168) {
      text = Localization.ques21LessThan168;
    }
    else if (val > 168) {
      text = Localization.ques21MoreThan168;
    }
    setAddedSugarText(text);

  };

  return (
    <div
      onClick={data}
      className="survey-container"
      style={
        showResult
          ? {
              background: "white",
              width: "85%",
              margin: "0 auto",
              Bottom: "45px",
            }
          : {}
      }
    >
      {!showResult && (
        <>
          <div className="survey-card">
            <div className="image-logo">
              <img
                className="logo mx-auto"
                src={langCode === "Spanish" ? LogoSpanish : Logo}
                alt=""
              />
              <p className="brand">
                {Localization.companyName} {Localization.nonProfit},{" "}
                <a href="mailto:gkapur@familygs.org">gkapur@familygs.org</a>
              </p>
              <p>{Localization.welcomeEat}</p>
              <strong className="org-name">{group}</strong>
            </div>
            <LangIcon onclick={() => setShowLangPopup(true)} />
          </div>
          <div className="survey-card">
            <h2>{Localization.howSharp}</h2>
            <p className="mb-2">{Localization.eatSharpAssessment}</p>
          </div>
        </>
      )}
      <form
        className="flex flex-col items-center"
        onSubmit={(event) => {
          calculateData(event);
        }}
      >
        {!showResult && (
          <>
            <div key="veganCard" className="survey-card">
              <div className="index-title">
                <div className="index"></div>
                <div className="title">{Localization.areYou19}</div>
              </div>

              <div className="options">
                <div className="radio">
                  {" "}
                  <input
                    className="option-input"
                    type="radio"
                    id="age"
                    name="age"
                    value="Yes"
                    onChange={() => setAge(true)}
                  />
                    <label htmlFor="age">{Localization.Yes}</label> {" "}
                </div>
                <div className="radio">
                  {" "}
                  <input
                    className="option-input"
                    type="radio"
                    id="ageNo"
                    name="age"
                    value="No"
                    onChange={() => setAge(false)}
                  />
                    <label htmlFor="ageNo">{Localization.No}</label>
                </div>
              </div>
            </div>
            {age === false && (
              <div className="survey-card">
                <h4>This assessment is not suitable.</h4>
              </div>
            )}
            {age && (
              <>
                {" "}
                <div className="survey-card">
                  <p>
                    {Localization.name}{" "}
                    {/* <span className="optional">({Localization.optional})</span> */}
                  </p>
                  <input
                    className="name-input"
                    onChange={setNameFunc}
                    type="text"
                    value={name}
                  />
                  <p className="mt-2 text-xs">
                    {Localization.provideNameAndEmail}
                  </p>
                </div>
                <div className="survey-card">
                  <p>
                    {Localization.email}{" "}
                    {/* <span className="optional">({Localization.optional})</span> */}
                  </p>
                  <input
                    className="name-input"
                    onChange={setEmailFunc}
                    type="text"
                    value={email}
                  />
                </div>
                <div className="survey-card">
                  <p>{Localization.date}</p>
                  <input
                    className="date-input"
                    type="date"
                    onChange={setDateFunc}
                    value={date}
                    required
                  />
                </div>
                <div key="veganCard" className="survey-card">
                  <div className="index-title">
                    <div className="index"></div>
                    <div className="title">{Localization.areVegan}</div>
                  </div>

                  <div className="options">
                    <div className="radio">
                      {" "}
                      <input
                        className="option-input"
                        type="radio"
                        id="Vegan"
                        name="vegan"
                        value="Yes"
                        onChange={veganFunc}
                        required
                      />
                        <label htmlFor="vegan">{Localization.Yes}</label> {" "}
                    </div>
                    <div className="radio">
                      {" "}
                      <input
                        className="option-input"
                        type="radio"
                        id="VeganNo"
                        name="vegan"
                        value="No"
                        onChange={veganFunc}
                        required
                      />
                        <label htmlFor="veganNo">{Localization.No}</label>
                    </div>
                  </div>
                </div>
                <div key="vegetarianCard" className="survey-card">
                  <div className="index-title">
                    <div className="index"></div>
                    <div className="title">{Localization.areVegetarian}</div>
                  </div>

                  <div className="options">
                    <div className="radio">
                      {" "}
                      <input
                        className="option-input"
                        type="radio"
                        id="Vegetarian"
                        name="Vegetarian"
                        value="vegeYes"
                        onChange={veganFunc}
                        required
                      />
                        <label htmlFor="Vegetarian">{Localization.Yes}</label> {" "}
                    </div>
                    <div className="radio">
                      {" "}
                      <input
                        className="option-input"
                        type="radio"
                        id="VegetarianNo"
                        name="Vegetarian"
                        value="vegeNo"
                        onChange={veganFunc}
                        required
                      />
                        <label htmlFor="VegetarianNo">{Localization.No}</label>
                    </div>
                  </div>
                </div>
                <div className="survey-card">
                  <h4>{Localization.past7Days}</h4>
                </div>
              </>
            )}
          </>
        )}

        {localSurveyData &&
          age &&
          !showResult &&
          localSurveyData.map((item, index) => {
            return (
              <div key={index} className="survey-card">
                <div className="index-title">
                  <div className="index">{index + 1}.</div>
                  <div className="title">{item.title}</div>
                </div>

                <div className="options">
                  {item.option &&
                    item.option.map((opt) => {
                      return (
                        <div className="radio">
                          {" "}
                          <input
                            className="option-input"
                            type="radio"
                            id={index + 1 + "-" + opt.key}
                            name={index + 1}
                            value={opt.value}
                            onChange={onRadioChange}
                            required
                          />
                           {" "}
                          <label htmlFor={index + 1 + "-" + opt.key}>
                            {opt.key}
                          </label>
                           {" "}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        {!showResult && age && <Ques21 calcQues21Result={calcQues21Result} />}
        {!showResult && age && !isVegan && !isVegetarian && (
          <div key="vegetarianCard" className="survey-card">
            <div className="index-title">
              <div className="index"></div>
              <div className="title">22. {Localization.ques22}</div>
            </div>

            <div className="options">
              <div className="radio">
                {" "}
                <input
                  className="option-input"
                  type="radio"
                  id="ques22No"
                  name="ques22"
                  value="didNotConsume"
                  onChange={setQues22AnswerFunc}
                  required
                />
                  <label htmlFor="ques22No">{Localization.didNotConsume}</label>
                 {" "}
              </div>
              <div className="radio">
                {" "}
                <input
                  className="option-input"
                  type="radio"
                  id="ques22LessThan4"
                  name="ques22"
                  value="lessThan4"
                  onChange={setQues22AnswerFunc}
                  required
                />
                 {" "}
                <label htmlFor="ques22LessThan4">
                  {Localization.lessThan4}
                </label>
              </div>
              <div className="radio">
                {" "}
                <input
                  className="option-input"
                  type="radio"
                  id="fourOrMore"
                  name="ques22"
                  value="fourOrMore"
                  onChange={setQues22AnswerFunc}
                  required
                />
                  <label htmlFor="fourOrMore">{Localization.fourOrMore}</label>
              </div>
            </div>
          </div>
        )}

        {!showResult && age && (
          <div className="w-[70%] mt-5">
            <button className="button" type="submit">
              {Localization.submit}
            </button>
          </div>
        )}
      </form>
      {showResult && (
        <ResultChart
          smartPoints={smartPoints}
          riskPoints={riskPoints}
          missedPoints={missedPoints}
          result={result}
          name={name}
          date={date}
          missedQstate={missedQstate}
          riskQstate={riskQstate}
          smartQstate={smartQstate}
          isVegan={isVegan}
          isVegetarian={isVegetarian}
          addedSugarText={addedSugarText}
          ques22Answer={
            ques22Answer == "didNotConsume"
              ? Localization.didNotConsumeAns
              : ques22Answer == "lessThan4"
              ? Localization.lessThan4Ans
              : ques22Answer == "fourOrMore"
              ? Localization.fourOrMoreAns
              : ""
          }
        />
      )}

      {showLangPopup && (
        <ChooseLanguage onclick={() => setShowLangPopup(false)} />
      )}

      <div></div>
    </div>
  );
};

export default Survey;
