import React, { useState, useEffect } from "react";
import rightArrow from "../../../assets/right-arrow.svg";
import close from "../../../assets/close.svg";
import "../survey.css";
import "./ques21.css";
import { Localization } from "../../../config/localization";

const Ques21 = ({ calcQues21Result }) => {
  const [yesSelected, setYesSelected] = useState(false);
  const [sugarVal, setSugarVal] = useState(0);
  const [sugarUnit, setSugarUnit] = useState("grams");
  
  /**
   * Calculates the number of Grams of suger.
   * 1 teaspoon = 4 grams.
   * @param {obj} event The event triggered by changing the inputs.
   */
  const calculateSugar = (event) => {

    let inputValue = event.target.value;
    let sugar = sugarVal;
    let sugarGrams = 0;

    if (inputValue == 'dontNo') {
      sugarGrams = -1;
      setYesSelected(false);
    }
    else if (inputValue == 'grams' || inputValue == 'teaspoons') {

      setSugarUnit(inputValue);

      if (inputValue == 'teaspoons') {
        sugarGrams = sugar * 4;
      }
      else {
        sugarGrams = sugar;
      }
    }
    else {

      setSugarVal(inputValue);

      if (sugarUnit == 'grams') {
        sugarGrams = inputValue;
      }
      else {
        sugarGrams = inputValue * 4;
      }
    }

    calcQues21Result(sugarGrams);
  };

  const quesSelect = (e) => {
    if (e.target.value ==="Yes") {
      setYesSelected(true);
    } else {
      setYesSelected(false);
    }
  };
  return (
    <>
      <div key="vegetarianCard" className="survey-card">
        <div className="index-title">
          <div className="index">21.</div>
          <div className="title">
           {Localization.ques21}
          </div>
        </div>

        <div className="options">
          <div className="radio">
            {" "}
            <input
              className="option-input"
              type="radio"
              id="ques21"
              name="ques21"
              value="Yes"
              onChange={quesSelect}
              required
            />
              <label htmlFor="ques21">{Localization.Yes}</label> {" "}
          </div>
          {yesSelected && (
            <>
              {" "}
              <div className="flex items-center">
                <input
                  className="sugar-input"
                  type="number"
                  min={0}
                  id="sugar"
                  name="sugar"
                  onChange={calculateSugar}
                  placeholder={Localization.enterNo}
                  required
                />
                <select
                  className="mx-3 bg-gray-200 p-[5px] rounded-sm focus:ring-0 focus:outline-0"
                  name="sugarUnit"
                  onChange={calculateSugar}
                >
                  <option value="grams">{Localization.grams}</option>
                  <option value="teaspoons">{Localization.teaspoons}</option>
                </select>

                <span className="text-sm">{Localization.duringPast7d}</span>
              </div>
            </>
          )}
          <div className="radio">
            {" "}
            <input
              className="option-input"
              type="radio"
              id="ques21No"
              name="ques21"
              value="dontNo"
              onChange={calculateSugar}
              required
            />
              <label htmlFor="ques21No">{Localization.dontKnow} </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ques21;
