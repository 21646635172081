import React, { useRef, useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import ReactToPrint from "react-to-print";

import Logo from "../../../assets/eatSharpLogo.png";
import LogoSpanish from "../../../assets/logoSpanish.png";

import { questionDesc, questionDescSpanish } from "./questionDesc";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Localization } from "../../../config/localization";

import "./resultChart.css";

const ResultChart = ({
  smartPoints,
  riskPoints,
  missedPoints,
  name,
  date,
  result,
  missedQstate,
  riskQstate,
  smartQstate,
  isVegan,
  isVegetarian,
  addedSugarText,
  ques22Answer,
}) => {
  let refData = useRef("");

  const [localQuestionDesc, setLocalQuestionDesc] = useState();
  const [langCode, setLangCode] = useState();
  const downloadPdfDocument = (rootElementId) => {
    const input = document.getElementById("textdoc");
    const { cropPositionTop, cropPositionLeft } = {
      cropPositionTop: 0,
      cropPositionLeft: 0,
    };
    html2canvas(input).then((canvas) => {
      // const imgData = canvas.toDataURL('image/png');
      // const pdf = new jsPDF();
      // pdf.addImage(imgData, 'JPEG', 10, 100);
      // pdf.save("download.pdf");
      let croppedCanvas = document.createElement("canvas");
      let croppedCanvasContext = croppedCanvas.getContext("2d");

      croppedCanvas.width = window.innerWidth;
      croppedCanvas.height =
        window.innerHeight + document.documentElement.scrollHeight;

      croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

      const a = document.createElement("a");
      a.href = canvas.toDataURL();
      a.download = "eatButSmartResult.jpg";
      a.click();
    });
  };
  const veganAndVegeQues = Localization.veganAndVegeQues;
  const veganQues = Localization.veganQues;
  const vegeQues = Localization.vegeQues;
  useEffect(() => {
    window.scrollTo(0, 0);

    const lang = window.localStorage.getItem("lang");
    setLangCode(lang);
    let updatedData;
    if (isVegan && isVegetarian) {
      updatedData = selectQuestionDesc(veganQues);
      console.log(updatedData);
      setLocalQuestionDesc(updatedData);
    } else if (isVegan) {
      updatedData = selectQuestionDesc(veganQues);
      console.log(updatedData);
      setLocalQuestionDesc(updatedData);
    } else if (isVegetarian) {
      updatedData = selectQuestionDesc(vegeQues);
      console.log(updatedData);
      setLocalQuestionDesc(updatedData);
    } else {
      if (lang === "Spanish") {
        setLocalQuestionDesc(questionDescSpanish);
      } else {
        setLocalQuestionDesc(questionDesc);
      }
    }
  }, []);

  const selectQuestionDesc = (ques) => {
    const lang = window.localStorage.getItem("lang");
    let finalQues = null;
    if (lang === "Spanish") {
      finalQues = questionDescSpanish;
    } else {
      finalQues = questionDesc;
    }
    let updatedData = finalQues.map((item, index) => {
      if (index === 9) {
        return {
          val: ques,
        };
      } else {
        return item;
      }
    });

    return updatedData;
  };
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div id="textdoc" ref={(response) => (refData = response)}>
        <div className="image-logo">
          <img
            className="logo mx-auto"
            src={langCode === "Spanish" ? LogoSpanish : Logo}
            alt=""
          />
          <p className="brand">
            FamilyGreenSurvival, Inc. 501c3 {Localization.nonProfit},{" "}
            <a href="mailto:gkapur@familygs.org">gkapur@familygs.org</a>
          </p>
        </div>

        <div className="right-pan-container">
          <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>
            {Localization.designToAssess}
          </h2>
          <p style={{ marginTop: "0px", marginBottom: "10px" }}>
            {Localization.sharpRange}
          </p>
          <div className="right-pan">
            {true ? (
              <>
                {" "}
                <div className="pie-container">
                  <PieChart
                    data={[
                      {
                        title: "SHARP Points",
                        value: smartPoints,
                        color: "#4caf50",
                      },
                      {
                        title: "Risk Points",
                        value: riskPoints,
                        color: "#f44336",
                      },
                      {
                        title: "Missed Points",
                        value: missedPoints,
                        color: "#ffeb3b",
                      },
                    ]}
                  />
                </div>
                <div className="points">
                  <div>
                    <div className="name">{name}</div>

                    <div className="date">
                      {Localization.date}: {Date(date).substring(4, 15)}
                    </div>
                    {result && (
                      <div className="consultancy">
                        <span style={{ fontWeight: "bold" }}>
                          {Localization.sharpStatus}
                        </span>{" "}
                        {result}
                      </div>
                    )}
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <table>
                      <tbody>
                        <p>
                          {" "}
                          <div className="sc smart"></div>{" "}
                          <tr className="row">
                            {" "}
                            <td className="title-res">
                              {Localization.sharpPoints}{" "}
                            </td>
                            <td>{smartPoints}</td>{" "}
                          </tr>
                        </p>

                        <p>
                          <div className="risk-container">
                            <div className="sc non-smart custom-set2"></div>
                            <tr className="row">
                              {riskQstate.filter((item) => item !== undefined)
                                .length > 0 ? (
                                <>
                                  <td className="title-res">
                                    {Localization.riskPoints}
                                  </td>
                                  <td>{riskPoints}</td>
                                </>
                              ) : (
                                <td>
                                  {Localization.No} {Localization.riskPoints}
                                </td>
                              )}
                              <td className="risk-ques">
                                {riskQstate.length > 0 ? (
                                  riskQstate.map((item, index) => {
                                    {
                                      return (
                                        item !== undefined && (
                                          <p>
                                            {" "}
                                            Q{item}
                                            {index !== riskQstate.length - 1 &&
                                              `,`}{" "}
                                          </p>
                                        )
                                      );
                                    }
                                  })
                                ) : (
                                  <p></p>
                                )}
                              </td>
                            </tr>
                          </div>
                        </p>
                        <p>
                          <div className="missed-container">
                            <div className="opp-container">
                              <div
                                className={
                                  missedQstate.length > 0
                                    ? "sc opportunity custom-set"
                                    : "sc opportunity"
                                }
                              ></div>
                            </div>
                            <tr className="row">
                              {missedQstate.length > 0 ? (
                                <>
                                  <td
                                    style={{ marginLeft: "20px" }}
                                    className="title-res"
                                  >
                                    {Localization.missedpoints}
                                  </td>
                                  <td style={{ marginLeft: "20px" }}>
                                    {missedPoints}
                                  </td>
                                </>
                              ) : (
                                <td style={{ marginLeft: "20px" }}>
                                  {Localization.No} {Localization.missedpoints}
                                </td>
                              )}
                              <td className="missed-ques">
                                {missedQstate.length > 0 &&
                                  missedQstate.map((item, index) => {
                                    return (
                                      <p>
                                        Q{item}
                                        {index !== missedQstate.length - 1 &&
                                          `,`}
                                      </p>
                                    );
                                  })}
                              </td>
                            </tr>
                          </div>
                        </p>

                        <p className="row">
                          <div className="sc missed"></div>
                          <tr className="row">
                            <td className="title-res">
                              {Localization.opportunityPoints}{" "}
                            </td>
                            <td>{missedPoints + riskPoints}</td>
                          </tr>
                        </p>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <p className="enter-info">{Localization.pleaseEnter}</p>
            )}
          </div>

          {smartPoints < 81 && (
            <div className="consultancy">
              <a
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "#3498db",
                }}
                href="https://www.cnn.com/2022/02/08/health/longer-life-diet-wellness/index.html "
                target="_blank"
              >
                {Localization.increaseGreen}
              </a>
            </div>
          )}

          <div className="definitions">
            <br />
            <strong></strong>
            {Localization.sharpPointDef}
            <br />
            <strong></strong>
            {Localization.riskPointDef}
            <br />
            <strong></strong>
            {Localization.missedPointDef}
            <br />
            <strong></strong>
            {Localization.opportunityPointDef}
          </div>

          <hr />
          {/* <p className="disclaimer">
          SAD: Standard American Diet, about the worst one can do.
        </p> */}
          {smartQstate.filter((item) => item !== undefined).length > 0 && (
            <p className="mt-3" dangerouslySetInnerHTML={{__html: Localization.bravoSharp}}></p>
          )}
          {smartQstate.length > 0 ? (
            smartQstate.map((item, index) => {
              {
                return (
                  item !== undefined && (
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<p className="ques-desc">${
                            localQuestionDesc &&
                            localQuestionDesc[Number(item - 1)].val
                          }</p>`,
                        }}
                      ></div>
                    </p>
                  )
                );
              }
            })
          ) : (
            <p></p>
          )}
          <hr />
          {missedQstate.length > 0 && (
            <p className="mt-3" dangerouslySetInnerHTML={{__html: Localization.missed}}></p>
          )}
          {missedQstate.length > 0 ? (
            missedQstate.map((item, index) => {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p className="ques-desc">${
                      localQuestionDesc &&
                      localQuestionDesc[Number(item - 1)].val
                    }</p>`,
                  }}
                ></div>
              );
            })
          ) : (
            <p></p>
          )}

          <hr />

          {riskQstate.filter((item) => item !== undefined).length > 0 ? (
            <p className="mt-3" dangerouslySetInnerHTML={{__html: Localization.risk}}></p>
          ) : (
            <p className="missed-title">{Localization.commendation}</p>
          )}

          {riskQstate.length > 0 ? (
            riskQstate.map((item, index) => {
              return (
                item !== undefined && (
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<p className="ques-desc">${
                          localQuestionDesc &&
                          localQuestionDesc[Number(item - 1)].val
                        }</p>`,
                      }}
                    ></div>
                  </p>
                )
              );
            })
          ) : (
            <p></p>
          )}

          {addedSugarText && (
            <div className="mt-2">
              <hr />
              <strong>{Localization.addedSugars}</strong>
              <br />
              <p dangerouslySetInnerHTML={{__html: addedSugarText}}></p>
            </div>
          )}
          {ques22Answer && (
            <div className="mt-2">
              <hr />
              <div>
                <strong>{Localization.ques22Title}</strong>
                <p dangerouslySetInnerHTML={{__html: ques22Answer}}></p>
              </div>
            </div>
          )}

          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="fb-link">
              <a
                style={{ color: "white" }}
                href="mailto:gkapur@familygs.org"
                target="_blank"
              >
                <div style={{ fontSize: "24px" }}>
                  {Localization.wasHelpfult}
                </div>
                {Localization.weWelcome} gkapur@familygs.org
              </a>
            </div>{" "}
          </div>

          <div className="disclaimer">{Localization.infoDisclaimer}</div>
          <div
            className="copyright"
            style={{ textAlign: "center", opacity: "1" }}
          >
            © {currentYear} FamilyGreenSurvival, Inc. {Localization.allRight}
          </div>
        </div>
      </div>
      <p className="check-again" onClick={() => window.location.reload(false)}>
        {Localization.checkAgain}
      </p>
      <button onClick={downloadPdfDocument} className="download-report">
        {" "}
        {Localization.downloadReport}
      </button>
    </div>
  );
};

export default ResultChart;
