import {useState} from 'react';
// import './dashboard.css';
import Login from './components/login';
import Home from './components/home';


const Dashboard = () => {

  const [isLoggedIn, setIsloggedIn] = useState(true);

  if (isLoggedIn) {
    return (
      <Home />
    );
  }
  else {
    return (
      <Login />
    )
  }
}

export default Dashboard;