import React, { useState } from "react";
import { Localization } from "../../../config/localization";
import QRCode from "react-qr-code";
import toImg from "react-svg-to-image";
import "./urlGenerator.css";
import { Link } from "react-router-dom";

const UrlGenerator = () => {

  const [group, setGroup] = useState('');
  const [copyBtnText, setCopyBtnText] = useState(Localization.copyLink);
  let url = window.location.origin;
  if (group) {
    url += '?group=' + encodeURIComponent(group);
  }
  const groupChange = (value) => {
    setGroup(value);
    setCopyBtnText(Localization.copyLink);
  }
  const copyUrl = () => {
    navigator.clipboard.writeText(url);
    setCopyBtnText(Localization.copied);
  }
  const saveQRCode = () => {
    let imgFileName = group ? group : 'qr-code';
    toImg( 'svg', imgFileName);
  }
  return (
    <div className="link-generator">
      <Link to="/">{'< ' + Localization.backToHome}</Link>
      <h2 className="linkgen-title">{Localization.urlGeneratorTitle}</h2>
      <div className="field-group">
        <label htmlFor="group">{Localization.urlGeneratorHelpText}:</label>
        <input type="text" id="group" onChange={(e) => groupChange(e.target.value) } />
      </div>

      <div className="generated-url">{url}</div>
      <div className="qr-code" id="123456">
        <QRCode value={url} level="M" />
      </div>
      <div className="action-buttons">
        <button className="copy-url" onClick={copyUrl}>{copyBtnText}</button>
        <button className="get-qr-code" onClick={saveQRCode}>{Localization.saveQRCode}</button>
      </div>
    </div>
  );
};

export default UrlGenerator;
