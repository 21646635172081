import React, { useState, useEffect } from "react";
import "./listOfPeople.css";
import { query, collection, getDocs, orderBy } from "firebase/firestore";
import db from "../../../firebase";
import Table from "../../molecules/table/table";

const ListOfPeople = () => {
  const [list, setList] = useState([]);
  const [spanishCount, setSpanishCount] = useState(0);
  useEffect(() => {
    async function fetchData() {
      const querySnapshot = await getDocs(collection(db, "EmailList"));
      // const querySnapshot = await getDocs(query(collection(db, "EmailList"), orderBy('date')));
      let index = 0;
      let arr = [];
      querySnapshot &&  querySnapshot.forEach((doc) => {
        index++;
        // doc.data() is never undefined for query doc snapshots

        arr.push(doc.data());
        // console.log(index, " => ", doc.data());
      });
const spanishCount = arr.filter((item)=>item.lang==="Spanish")

setSpanishCount(spanishCount)
      setList(arr);
    }
    fetchData();
  }, []);
  return (
    <div>
<div className="survey-card w-100">
      <h2>Total Survey : {list.length ? list.length :<div className="load">Loading....</div>}</h2>
      <h2>Spanish : {spanishCount.length ? spanishCount.length :<div className="load">Loading....</div>}</h2>
      </div>
    
    <div className="survey-card w-100">
      
      <div>
        {list &&
          list.length > 0 ?
         <Table list={list} />
        
        :<div className="loader"></div>}
              
               
          
            
            
        
      </div>
    </div>
    </div>
  );
};

export default ListOfPeople;
