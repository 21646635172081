// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore"
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAzLzCS6Zn3-TL6Ig6JCFNq4iVGXutUaPc",
  authDomain: "eatbutsmart.firebaseapp.com",
  projectId: "eatbutsmart",
  storageBucket: "eatbutsmart.appspot.com",
  messagingSenderId: "402587394275",
  appId: "1:402587394275:web:69611ffb6c102be5cae5d6"
};

// Initialize Firebase

const fire = initializeApp(firebaseConfig);
const db = getFirestore(fire);

export default db;