import React, { useState, useEffect } from "react";
import "./chooseLanguage.css";
import close from "../../../assets/close.svg";


const ChooseLanguage = ({onclick}) => {
  const [language, setLanuguage] = useState(window.localStorage.getItem("lang"));

  // useEffect(() => {
  //   const lang = window.localStorage.getItem("lang")
  //   setLanuguage(lang ? lang:"English")
  //   console.log('0000, ', lang );
  //   console.log('1111, ', language );
  // }, [])
  
  function onChangeValue(event) {
    setLanuguage(event.target.value);
    console.log(event.target.value);
    window.localStorage.setItem("lang",event.target.value)
    window.location.reload()
  }
  return (
    <div className="popupContainer">
      <div className="popup">

        <h2>Choose Language</h2>


            <img onClick={onclick} className="close" src={close} alt="" />
       

        <div className="radio-toolbar" onChange={onChangeValue}>
  <input type="radio" id="radio1" name="language"  value="English" defaultChecked={language === "English"}/>
  <label htmlFor="radio1">English</label>
  <input type="radio" id="radio2" name="language" value="Spanish" defaultChecked={language === "Spanish"}/>
  <label htmlFor="radio2">Spanish</label>
 
</div>
      </div>
    </div>
  );
};

export default ChooseLanguage;
