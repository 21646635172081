export const questionDesc = [
  {
    val: `<strong>Q1.</strong> Eating beans and lentils, preferably home-cooked, three to four times a week is a healthy substitute for meat. Beans and lentils are naturally high in protein, complex carbs, dietary fiber, trace minerals, and antioxidants. Their nutrition is linked to a lower risk of diabetes, heart disease, and colorectal cancer. Resist the urge to cook them with fatty meats. To minimize lectins in beans and lentils, it’s best to presoak and then cook in a pressure cooker. Never eat beans or lentils raw in any form. For wholesome, delicious, and easy-to-cook recipes, visit <a href="https://familygreensurvival.org/eat-sharp/" target="_blank">Kapur Cuisine</a> and click Beans Galore.`,
  },
  {
    val: "<strong>Q2.</strong> Most boxed cereals contain refined flours, low dietary fiber, high added sugar, harmful added chemicals, and toxic colors. Read nutrition labels diligently and avoid sugar and chemicals loaded, health-harming products; supermarket shelves are full of them. Look for cereal where per cup serving has 4g or more of dietary fiber, 4g or more protein, and no more than 4g of sugar.  Don’t be fooled by the Organic labels; many have too much added sugar and are low in dietary fiber.",
  },

  {
    val: `<strong>Q3.</strong> Berry consumption is linked with lower heart disease, Alzheimer’s disease, and diabetes risks. Eat two ½ cup servings of berries, especially blueberries, each week for brain health. Cherries help reduce blood sugar, improve cholesterol, reduce blood pressure, and reduce inflammation. Pomegranate shows the possibility of helping lower blood pressure, cholesterol, and blood sugar levels. For delicious, wholesome, easy-to-prepare berry desserts, visit <a href="https://familygreensurvival.org/eat-sharp/" target="_blank">Kapur Cuisine</a> and click Fruit Desserts.`,
  },

  {
    val: "<strong>Q4.</strong> Eating about an ounce of unfried, unsalted nuts and seeds five times a week is sufficient. Nuts and seeds are excellent sources of protein, dietary fiber, vitamins, trace minerals, and healthy fats. Beware, nuts and seeds are high in calories. The lectins in nuts and seeds are water-soluble and located on the outer surface; soaking nuts and seeds minimizes lectins.",
  },
  {
    val: `<strong>Q5. Olive Oil:</strong> “… when olive oil was replacing butter, mayonnaise, margarine, and other animal fats, it was associated with lower risk of total mortality and mortality from causes such as cancer, cardiovascular disease or neurodegenerative diseases,” Harvard T.H. Chan School of Public Health. We prefer California-produced Extra Virgin Olive Oil; it’s better quality than most imported olive oils. 
    <br/><br/>
    <strong>Avocado:</strong> Rich in dietary fiber, folate, Omega-3, potassium, vitamins C and K. Eating at least two avocado servings (1 avocado or 1 cup) a week reduced the risk of having a heart attack by 21% in both men and women. Journal of the American Heart Association, March 30, 2022. Beware, avocados are high in calories; a serving of avocado is 1.7 ounces or 50 grams, and a single avocado can be from 3 to 5 servings. 
    <br/><br/>
    In an oil-free diet, the body needs fat to help absorb fat-soluble vitamins like A, D, E, and K and build cell membranes and hormones. Make sure to consume plant-based healthy fat from avocados, chickpeas, edamame, nuts, non-GMO soy products, and seeds. The needed amount may differ depending on age, activity level, and health profile.`,
  },
  {
    val: `<strong>Q6.</strong> Aim for two or more hearty servings (10 to 12 oz) of non-starchy vegetables daily: arugula, beet greens, bok choy, collard greens, eggplant, kale, methi (fenugreek), mustard greens, okra, rapini, spinach, Swiss chard, turnip greens, and watercress. "Our results have shown that by simply eating half a cup of cooked nitrate-rich vegetables each day – such as kale, arugula, chard and spinach, beetroot, parsley, Chinese cabbage, celery, radishes, and turnips – people may be able to significantly reduce their risk of cardiovascular disease." Dr. Catherine Bondonno, NHIRI. For wholesome, delicious, and easy-to-cook recipes, visit <a href="https://familygreensurvival.org/eat-sharp/" target="_blank">Kapur Cuisine</a>.`,
  },
  {
    val: "<strong>Q7.</strong> Water makes up about 50% to 70% of the body weight. Lack of timely water replenishment can lead to dehydration, resulting in dizziness, confusion, weakness, and fainting with serious outcomes. Black and green tea can help boost the immune system and fight inflammation. Green tea is exceptionally high in flavonoids that can help promote heart health. It’s wise to drink a glass of water every couple of hours during the day.",
  },
  {
    val: "<strong>Q8.</strong> Spices add flavor, aroma, and taste to bland food. Additionally, spices fight inflammation and reduce damage to body cells because they are rich in healthful phytochemicals. Use a variety of spices in small quantities. It’s best to use spices in their natural form than using pills or capsules.",
  },
  {
    val: "<strong>Q9.</strong> If you must eat fast food, limit it to once or twice a month; don't oversize. Eating fast food routinely can lead to increased risks of obesity and chronic diseases – cardiovascular disease, T2 diabetes, nonalcoholic fatty liver disease and some cancers. Consumer Reports found PFAS, known as forever chemicals, in bowls, bags, plates and wrappers, even from companies claiming they've phased them out. Alarming levels of PFAS were discovered in food packaging at several well-known fast-food and fast-casual restaurants and grocery store chains... the human-made chemicals can harm the immune system and reduce a person's resistance to infectious diseases.",
  },
  {
    val: "<strong>Q10.</strong> Consuming the right amount of Omega–3s can help reduce the inflammation that can lead to chronic conditions, asthma, cardiovascular disease, and autoimmune diseases. Incorporate fatty fish, including anchovies, herring, mackerel, wild-caught salmon, sardines, striped bass, or rainbow trout, two to three servings a week; make sure the fish is not battered or fried. A serving is 3.5 oz or 100g.",
  },
  {
    val: "<strong>Q11.</strong> Herbs have been used for their potent antioxidant and anti-inflammatory effects since ancient times. Consuming herbs may help manage heart disease, cancer, and T2 diabetes. Incorporate fresh or dry herbs in your daily meals; it’s best to grow two or three varieties on your own.",
  },
  {
    val: "<strong>Q12.</strong> Incorporate garlic in your savory meals. According to the Cleveland Clinic, garlic can boost immunity and improve cardiovascular health. Rich in vitamin C, B6, and manganese, garlic contains antioxidants that can help protect against cell damage. For the best health benefits, add garlic when you’re almost done cooking the dish.",
  },
  {
    val: `<strong>Q13. Good News:</strong> Dietary fiber feeds the good bacteria in the gut, helping regulate inflammation, strengthen the immune system, reduce the risk of obesity, heart disease, T2 diabetes, and some cancers. The best source of high-quality dietary fiber includes beans, lentils, fruits (avocado, blackberries, figs, passion fruit, pears, prunes, raspberries), nuts, seeds, vegetables (asparagus, broccoli, cauliflower, eggplant, purple cabbage), wheat bran, and whole grains.
    <br>
    <strong>Bad News:</strong> The average American gets 15 grams per day, an insufficient amount. The recommended amount is at least 25 grams for women and 38 grams for men. Relying on bars, gummies, and powders for dietary fiber can do more harm than good.`,
  },
  {
    val: "<strong>Q14.</strong> Eat an oats-based breakfast two to three times a week; include some barley, quinoa, hemp seed, cinnamon, and berries. Oats are rich in protein, dietary fiber, and antioxidants. Oats can help lower cholesterol levels and the risk of colon cancer and maintain digestive health. For sweeteners, use a small quantity of pure maple syrup, minimally processed honey, or a couple of minced dates.",
  },
  {
    val: "<strong>Q15.</strong> Eat about 2 cups of fruit daily. Fruits are an excellent source of essential vitamins and minerals and are high in dietary fiber. Eating a variety of fruits routinely can reduce the risk of developing heart disease, inflammation, and certain cancers.",
  },
  {
    val: "<strong>Q16.</strong> If you must have soft drinks, limit yourself to a couple of drinks a week, don’t oversize. People who consistently drink sugar-sweetened beverages gain weight; excessive consumption is linked to insulin resistance, increased belly fat, obesity, and nonalcoholic fatty liver disease (NFLD). Avoid sodas with HFCS (high fructose corn syrup).“Artificial sweeteners linked with a 13% higher risk of cancer in general, with the highest likelihood of developing breast cancer and cancers related to obesity, for people consuming large quantities of artificial sweeteners.” PLOS Medicine, March 24, 2022. “Energy drinks usually contain large amounts of sugar ranging from 21 g to 34 g per oz in the form of high fructose corn syrup. Recent reports have demonstrated a relationship between energy drink overconsumption and arterial dilatation, aneurysm formation, dissection, and rupture of large arteries.” National Library of Medicine.",
  },
  {
    val: "<strong>Q17.</strong> Eating whole grains, preferably home-cooked, three to four times a week is a healthy substitute for meat. Whole grains are naturally high in protein, complex carbs, dietary fiber, trace minerals, and antioxidants. Their nutrition is linked to a lower risk of diabetes, heart disease, colorectal cancer, and nonalcoholic fatty liver disease (NAFLD).",
  },
  {
    val: "<strong>Q18.</strong> Minimize ultraprocessed meals to no more than twice a month. Ultraprocessed food is manufactured by replacing natural ingredients with additives, preservatives, sweeteners, chemical colors, stabilizers, curing agents, unhealthy fats, and sugars. Routine consumption of ultra-processed food can lead to chronic inflammation, obesity, T2D, hypertension, heart disease, and some cancers.",
  },
  {
    val: `<strong>Q19.</strong> Whenever possible, avoid water from plastic bottles. "Arsenic in Some Bottled Water Brands at Unsafe Levels." Consumer Reports, June 28, 2019. Arsenic, a toxic metal, can cause reproductive harm, cancer, and birth defects. Then, there is the problem of ingesting microplastics released from the plastic bottles. Work towards not buying bottled water; perhaps use a countertop water filter unit.`,
  },
  {
    val: "<strong>Q20.</strong> Eat two to four servings of probiotic-rich foods, buttermilk, green peas, green olives, kefir, kimchi, kombucha, miso, natto, sauerkraut, sourdough bread, tempeh, Greek yogurt, plain yogurt, vegan yogurt (unsweetened and no added fruit or color), each week. Probiotics help balance the friendly bacteria in the digestive system, boost the immune system, help reduce inflammation, and promote heart health. Unless prescribed by a qualified health professional, avoid probiotics in the form of gummies, liquids, pills, and powders.",
  },
];

export const questionDescSpanish = [
  {
    val: "<strong>Q1.</strong> Comer frijoles y lentejas, preferiblemente caseros, de tres a cuatro veces por semana es una alternativa saludable a la carne. Los frijoles y las lentejas son naturalmente ricos en proteínas, carbohidratos complejos, fibra dietética, minerales y antioxidantes. Su consumo regular está relacionado con un menor riesgo de diabetes, enfermedades cardíacas y cáncer colorrectal.No es recomendable cocinarlos con carnes grasosas tales como chorizos, salchichas o jamones. Para minimizar las lectinas, una toxina presente en los frijoles, por lo que es mejor remojarlos previamente y luego cocinarlos en una olla a presión. Si usa una olla de cocción lenta, primero, los frijoles deben remojarse en agua durante al menos cinco horas, escurrirlos y enjuagarlos, y hervirlos en agua durante al menos 30 minutos. Nunca coma frijoles o lentejas crudos en ninguna forma. Para obtener recetas saludables, deliciosas y fáciles de cocinar, visite EatSHARP y haga clic en la sección de Beans Galore.",
  },
  {
    val: "<strong>Q2.</strong> La mayoría de los cereales empacados contienen harinas refinadas, un bajo contenido de fibra dietética, un alto contenido de azúcar añadido, así como productos químicos nocivos añadidos y colorantes alimenticios tóxicos. Lea las etiquetas con información nutricional con atención y evite productos nocivos para la salud cargados de azúcar y productos químicos; los estantes de los supermercados están abarrotados con ellos.",
  },
  {
    val: "<strong>Q3.</strong> El consumo de moras está relacionado con un menor riesgo de enfermedades cardíaca, Alzheimer y diabetes. Coma dos porciones de ½ taza de bayas, especialmente arándanos o también conocidos como mora azul, cada semana, para mejorar la salud de su cerebro. Para aprender a preparar postres deliciosos con moras, que sean saludables y fáciles de preparar, visite EatSHARP y haga clic en el cuadro Fruit Desserts. Las cerezas ayudan a reducir el azúcar en la sangre, mejoran el nivel de colesterol, reducen la presión arterial y reducen la inflamación. La granada puede ayudar a reducir la presión arterial, el colesterol y los niveles de azúcar en la sangre.",
  },
  {
    val: "<strong>Q4.</strong> Se recomienda comer alrededor de una onza de nueces y semillas sin freír y sin sal cinco veces a la semana. Las nueces y las semillas son excelentes fuentes de proteínas, fibra dietética, vitaminas, minerales y grasas saludables. Pero con cuidado porque las nueces y las semillas son ricas en calorías. Las lectinas en nueces y semillas son solubles en agua y se encuentran en la superficie exterior; remojar nueces y semillas minimiza las lectinas.",
  },
  {
    val: "<strong>Q5.<strong> Aceite de oliva. Cuando se reemplaza la mantequilla, la mayonesa, la margarina y otras grasas animales con aceite de oliva, esto se asociaba con un menor riesgo de mortalidad general y mortalidad por cáncer, enfermedades cardiovasculares o enfermedades neurodegenerativas”, Harvard T.H. Escuela Chan de Salud Pública.Debemos preferir el aceite de oliva virgen extra producido en California; es de mejor calidad que la mayoría de los aceites de oliva importados. Aguacate: rico en fibra dietética, ácido fólico, Omega-3, potasio, vitaminas C y K. Comer al menos dos porciones de aguacate (1 aguacate o 1 taza) a la semana se encontró estar relacionado a la reducción de sufrir un ataque cardíaco en un 21 % tanto en hombres como en mujeres. Woman´s Journal of the American Heart Association, 30 de marzo de 2022. Advertencia, los aguacates son altos en calorías; una porción de aguacate son 1.7 onzas o 50 gramos, y un solo aguacate puede tener de 3 a 5 porciones. Dietas libres de aceite: el cuerpo necesita grasa para ayudar a absorber las vitaminas liposolubles como A, D, E y K, y construir membranas celulares y hormonas. Asegúrese de consumir grasas saludables de origen vegetal, provenientes de aguacates, garbanzos, edamames, nueces, alubias, productos de soya sin grasas hidrogenadas y semillas. La cantidad puede variar según la edad, el nivel de actividad y el perfil de salud.",
  },
  {
    val: `<strong>Q6.</strong> Trate de comer dos o más porciones abundantes de verduras todos los días, con especial énfasis en las verduras de hoja verde oscuro: arúgula, hojas de betabel, bok choy (<span class="highlighted">col china</span>), hojas de zanahoria, col rizada, kale, fenogreco, hojas de mostaza, espinacas, acelgas, hojas de nabo, o berros. “Nuestros resultados han demostrado que simplemente comiendo media taza de nitrato rico en verduras todos los días, como col rizada, rúcula, acelgas y espinacas, remolacha, perejil, repollo Chino, apio, rábanos y nabos- las personas pueden ser capaces dereducir su riesgo de enfermedad cardiovascular.” Dra. Catherine Bondonno, NHIRI. Para obtener recetas saludables, deliciosas y fáciles de cocinar, visite <a href="https://familygreensurvival.org/eat-sharp/" target="_blank">Recetas EatSHARP</a>.`,
  },
  {
    val: "<strong>Q7.</strong> El agua constituye alrededor del 50% al 70% de nuestro peso corporal. La falta de reposición oportuna de agua puede conducir a la deshidratación, la cual produce mareos, confusión, debilidad y desmayos con resultados graves. El té, negro y verde, pueden ayudar a estimular el sistema inmunológico y combatir la inflamación. El té verde es excepcionalmente alto en flavonoides que pueden ayudar a promover la salud del corazón. Es aconsejable beber un vaso de agua cada dos horas durante el día.",
  },
  {
    val: "<strong>Q8.</strong> Las especias agregan sabor, aroma y gusto a los alimentos de sabor suave. Además, algunas especias combaten la inflamación y reducen el daño a las células del cuerpo porque son ricas en fitoquímicos saludables. Usar especias en su forma natural es mejor que consumir pastillas o cápsulas.",
  },
  {
    val: `<strong>Q9.</strong> Si debe comer comida rápida, limítela a una o dos veces al mes, no sobredimensione. Comiendo rapido los alimentos de forma rutinaria pueden conducir a un mayor riesgo de obesidad y enfermedades crónicas-enfermedades cardiovasculares, diabetes T2, hígado graso no alcohólico y algunos cánceres. Consumer Reports encontró PFAS, conocidas como sustancias químicas para siempre, en tazones, bolsas, platos y envoltorios, incluso de algunas empresas que dicen que los han eliminado. Se descubrieron niveles alarmantes de PFAS en envases de alimentos en una serie de conocidos restaurantes de comida rápida e informales y cadenas de tiendas de abarrotes...los productos químicos pueden dañar el sistema inmunológico y reducir la resistencia de una persona a las enfermedades infecciosas.`,
  },
  {
    val: "<strong>Q10.</strong> Para mejorar el consumo de omega-3, incorpore pescado graso, como anchoas, arenque, macarela, salmón salvaje, sardinas, lubina rayada o trucha arcoíris, de dos a tres porciones a la semana; asegúrese de que el pescado no esté empanizado o frito. Una porción es de 3,5 oz o 100 g.",
  },
  {
    val: "<strong>Q11.</strong> Incorpora hierbas frescas o secas en tus comidas diarias; Las hierbas se han utilizado por sus potentes efectos antioxidantes y antiinflamatorios desde la antigüedad. Para tener fácil y continuo acceso a ellas, es recomendable cultivar dos o tres variedades de ellas en casa.",
  },
  {
    val: "<strong>Q12.</strong> Incorpore el ajo en sus comidas diarias. Según la Clínica Cleveland, el ajo puede aumentar la inmunidad y mejorar la salud cardiovascular. Rico en vitamina C, vitamina B6 y manganeso, el ajo contiene antioxidantes que pueden ayudar a proteger contra el daño celular. Agregue el ajo cuando casi haya terminado de cocinar el platillo para aprovechar sus mejores beneficios para la salud",
  },
  {
    val: "<strong>Q13.</strong> Buenas noticias: la fibra dietética alimenta las bacterias buenas del intestino, lo que ayuda a regular la inflamación, fortalece el sistema inmunológico, reduce el riesgo de obesidad, enfermedades cardíacas, diabetes tipo2 y algunos tipos de cáncer. La mejor fuente de fibra dietética de alta calidad incluye frijoles, lentejas, frutas (aguacate, moras, higos, maracuyá, peras, ciruelas pasas, frambuesas), nueces, semillas, verduras (espárragos, brócoli, coliflor, berenjenas, repollo morado), salvado de trigo y cereales integrales Malas noticias: el estadounidense en promedio consume 15 gramos por día, una cantidad insuficiente. La cantidad recomendada es de al menos 25 gramos para mujeres y 38 gramos para hombres. Confiar en barras, gomitas y polvos para obtener fibra dietética puede hacer más daño que bien.",
  },
  {
    val: "<strong>Q14.</strong> Coma un desayuno a base de avena dos o tres veces por semana; incluya un poco de quinoa, semillas de cáñamo, semillas de lino molidas, canela y bayas. Como edulcorante, use una pequeña cantidad de jarabe de maple puro, miel mínimamente procesada o un par de dátiles picados. La avena es rica en proteínas, fibra dietética y antioxidantes. También puede ayudar a reducir los niveles de colesterol y el riesgo de cáncer de colon y mantener la salud digestiva. Como edulcorante, use una pequeña cantidad de jarabe de maple puro, miel mínimamente procesada o un par de dátiles picados sin hueso al consumirla.",
  },
  {
    val: "<strong>Q15.</strong> Coma alrededor de 2 tazas de fruta fresca al día. Las frutas son una excelente fuente de vitaminas y minerales esenciales y tienen un alto contenido de fibra dietética. Comer frutas de manera rutinaria puede reducir el riesgo de desarrollar enfermedades cardíacas, inflamación y ciertos tipos de cáncer.",
  },
  {
    val: "<strong>Q16.</strong> Si debe tomar refrescos, limítese a un par a la semana. Las personas que beben constantemente bebidas azucaradas aumentan de peso; el consumo de refrescos excesivo está relacionado con la resistencia a la insulina, al aumento de la grasa abdominal, obesidad y la enfermedad del hígado graso no alcohólico (NFLD). Evite los refrescos con JMAF (jarabe de maíz de alta fructosa). “Los edulcorantes artificiales se relacionan con un 13 % más de riesgo de cáncer en general, esto es, con una mayor probabilidad de desarrollar cáncer de mama y cánceres relacionados con la obesidad, esto en personas que consumen grandes cantidades de edulcorantes artificiales”. Medicina PLOS, 24 de marzo de 2022.",
  },
  {
    val: "<strong>Q17.</strong> Comer cereales integrales, preferiblemente cocinados en casa, y de tres a cuatro veces por semana es una alternativa saludable de la carne. Los cereales integrales son naturalmente ricos en proteínas, carbohidratos complejos, fibra dietética, minerales y antioxidantes. Su consumo está relacionado con un menor riesgo de diabetes, enfermedades cardíacas, cáncer colorrectal y enfermedad del hígado graso no alcohólico (EHGNA).",
  },
  {
    val: `<strong>Q18.</strong> Los alimentos ultra procesados se fabrican reemplazando ingredientes naturales con aditivos, conservadores, edulcorantes, colorantes químicos, estabilizadores, sales para curar alimentos, grasas no saludables y azúcares. El consumo constante de alimentos ultra procesados puede provocar inflamación crónica, obesidad, Diabetes Tipo 2 (DT2), hipertensión, enfermedades cardíacas y algunos tipos de cáncer.Malas noticias: "Las personas que reportaron una mayor ingesta de alimentos ultra procesados fueron significativamente más propensas a reportar una depresión leve, más enfermedades mentales y más días de ansiedad". Prensa de la Universidad de Cambridge, 28 de julio de 2022.
Más malas noticias: “Existe alimentos ultra procesados relacionados con el cáncer y la muerte prematura. Comer grandes cantidades de alimentos ultra procesados aumenta significativamente el riesgo de cáncer colorrectal en los hombres y puede provocar enfermedades cardíacas y muerte prematura tanto en hombres como en mujeres”. British Medical Journal, 31 de agosto del 2022. 
Los estadounidenses consumen un gran porcentaje de sus calorías diarias de alimentos ultra procesados: 58 % en adultos y 67 % en niños.
Acciones recomendadas: Esfuércese para minimizar su consumo de alimentos ultra procesados.`,
  },
  {
    val: `<strong>Q19.</strong> Siempre que sea posible, evite el agua de las botellas de plástico. “El Arsénico en Algunas Botellas Marcas de Agua en Niveles Inseguros.” Consumer Reports, 28 de junio de 2019. Arsénico, un tóxico metal, puede causar daño reproductivo, cáncer y defectos de nacimiento. Luego está el problema de ingerir microplásticos liberados de las botellas de plástico.`,
  },

  {
    val: "<strong>Q20.</strong> Coma de dos a cuatro porciones de alimentos ricos en probióticos cada semana. Los probióticos ayudan a equilibrar las bacterias amistosas en el sistema digestivo, estimulan el sistema inmunológico, ayudan a reducir la inflamación y promueven la salud del corazón. A menos que los recete un profesional de la salud calificado, evite los probióticos en forma de gomitas, líquidos, pastillas y polvos.",
  },
];
