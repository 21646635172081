export const surveyData = [
  {
    index: 1,
    title:
      "Consume any beans or lentils without added sugar, bacon, ham, or deli meat? ",
    option: [
      { key: "Yes", value: 10 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 2,
    title:
      "Consume any boxed cereal with more than 6 grams of added sugar per serving or less than 3 grams of dietary fiber or with chemical food coloring (each is listed on the product nutrition label)?",
    option: [
      { key: "Yes", value: -4 },
      { key: "No", value: 0 },
      { key: "Don’t Know", value: -4 },
    ],
  },
  {
    index: 3,
    title:
      "Consume any berries (preferably blueberries), cherries, or pomegranates without added sugar?",
    option: [
      { key: "Yes", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 4,
    title: "Consume any unfried or unsalted nuts or seeds?",
    option: [
      { key: "Yes", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 5,
    title:
      "Consume extra virgin olive oil or plant-based healthy fat from avocados, chickpeas, edamame, nuts, non-GMO soy products, and seeds?",
    option: [
      { key: "Yes", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 6,
    title:
      "Consume two to three cups (10 to 12 oz) of non-starchy vegetables daily?",
    option: [
      { key: "Yes", value: 10 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 7,
    title:
      "Consume at least four glasses daily of any combination of water, unsweetened fruit-infused water, water-diluted fruit juice, milk, black or green tea, chai, herbal tea, coffee, and unsweetened plant-based beverage?",
    option: [
      { key: "Yes", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 8,
    title: "Consume any cayenne, cinnamon, ginger, or turmeric?",
    option: [
      { key: "Yes", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 9,
    title: "Consume any food from fast-food restaurants?",
    option: [
      { key: "Yes", value: -10 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 10,
    title:
      "Consume, for omega 3, any algae, avocado, Brussels sprouts, chia seed, edamame, flaxseed, hemp seed, kidney beans, navy beans, seaweed, spinach, and walnuts?",
    option: [
      { key: "Yes", value: 12 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 11,
    title: "Consume any fresh or dry herbs?",
    option: [
      { key: "Yes", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 12,
    title: "Consume any garlic?",
    option: [
      { key: "Yes", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 13,
    title:
      "Consume the recommended daily amount of natural dietary fiber (at least 25 grams for women and 38 grams for men) from beans, lentils, whole grains, fruits, and vegetables; not from bars, gummies, and powders?",
    option: [
      { key: "Yes", value: 8 },
      { key: "No", value: 0 },
      { key: "Don’t Know", value: 0 },
    ],
  },
  {
    index: 14,
    title:
      "Consume any oats (rolled, steel-cut, or whole), not instant or quick?",
    option: [
      { key: "Yes", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 15,
    title:
      "Consume any fruit: fresh, frozen, canned in its own juice, or unsweetened dried fruit?",
    option: [
      { key: "Yes", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 16,
    title:
      "Consume any sweetened (including artificial sweeteners) soft drink or energy drink?",
    option: [
      { key: "Yes", value: -4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 17,
    title:
      "Consume any whole grains? For example, amaranth, barley, brown rice, buckwheat, bulgur, corn, farro, freekeh, millet, popcorn (air-popped or similar), quinoa, rye, sorghum, spelt, teff, whole wheat, or wild rice; all without added sugar, bacon, ham, or any deli meat?",
    option: [
      { key: "Yes", value: 8 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 18,
    title:
      "Consume any ultra-processed ready-to-heat and eat food, which is typically factory-manufactured with added preservatives, sweeteners, chemical colors, flavors, stabilizers, and sugars.",
    option: [
      { key: "Yes", value: -8 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 19,
    title: "Purchase drinking water in plastic bottles for personal use?",
    option: [
      { key: "Yes", value: -4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 20,
    title:
      "Consume any probiotics, including buttermilk, green peas, green olives, kefir, kimchi, kombucha, miso, natto, sauerkraut, sourdough bread, tempeh, Greek yogurt, plain yogurt, vegan yogurt (unsweetened and no added fruit or color)?",
    option: [
      { key: "Yes", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  // {
  //   index: 21,
  //   title:
  //     "Daily, consume less than 6 teaspoonsof added sugar (female) and less than 9 teaspoons of added sugar(male)?",
  //   option: [
  //     { key: "Yes", value: 5 },
  //     { key: "No", value: -5 },
  //   ],
  // },
  // {
  //   index: 22,
  //   title:
  //     "Daily, consume less than 2,300mg sodium; if over 51, less than 1,500mg?",
  //   option: [
  //     { key: "Yes", value: 5 },
  //     { key: "No", value: -5 },
  //   ],
  // },
  // {
  //   index: 23,
  //   title:
  //     "Consume high fructose corn syrup (HFCS) or hydrogenated oil?",
  //   option: [
  //     { key: "Yes", value: 6 },
  //     { key: "No", value: 0 },
  //   ],
  // },
];

export const surveyDataSpanish = [
  {
    index: 1,
    title:
      "¿Consumió cualquier tipo de frijoles o lentejas que no contuvieran azúcar añadido ni tocino, ni chorizo, jamón ni ninguna otra carne procesada?",
    option: [
      { key: "Sí", value: 10 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 2,
    title:
      "¿Consumió cualquier tipo de cereal empacado con másde 6 gramos de azúcar añadido por porción o consumió algún cereal con colorantes artificiales añadidos? (Puede verificar el contenido de sus alimentos en los valores nutricionales e ingredientes contenidos en la etiqueta del empaque de estos).",
    option: [
      { key: "Sí", value: -4 },
      { key: "No", value: 0 },
      { key: "No sé", value: -4 },
    ],
  },
  {
    index: 3,
    title:
      "¿Consumió cualquier tipo de moras (preferentemente moras azules o “blueberries”), cerezas o granada sin que tuvieran ningún tipo de azúcar añadida?",
    option: [
      { key: "Sí", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 4,
    title:
      "¿Consumió cualquier tipo de nueces o semillas sin que tuvieran sal añadida?",
    option: [
      { key: "Sí", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 5,
    title:
      "¿Consumió aceite de oliva extra virgen o grasas saludables de origen vegetal tales como aguacate, garbanzos, habas, productos de soya no hidrogenados o semillas?",
    option: [
      { key: "Sí", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 6,
    title: "¿Consumió dos a tres tazas de vegetales al día?",
    option: [
      { key: "Sí", value: 10 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 7,
    title:
      "¿Consumió por lo menos 4 vasos diarios de cualquier combinación de agua, agua infusionada de frutas sin azúcares añadidos, agua diluida jugo de frutas, té verde o negro, té herbal, chai, café o bebidas de base vegetal no azucaradas?",
    option: [
      { key: "Sí", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 8,
    title: "¿Consumió pimiento de cayena, canela, jengibre o cúrcuma? ",
    option: [
      { key: "Sí", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 9,
    title: "¿Consumió comida de restaurantes de comida rápida?",
    option: [
      { key: "Sí", value: -10 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 10,
    title:
      "¿Ha consumido Omega 3, pescado graso incluyendo anchoas, arenque, macarela, salmón, sardinas, lobina o trucha?",
    option: [
      { key: "Sí", value: 12 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 11,
    title: "¿Consumió hierbas frescas o secas?",
    option: [
      { key: "Sí", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 12,
    title: "¿Consumió ajo?",
    option: [
      { key: "Sí", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 13,
    title:
      "¿Consumió la cantidad diaria recomendada de fibra dietética (al menos 25 gramos para las mujeres y 38 gramos para los hombres) proveniente de frijoles, lentejas, granos enteros, frutas y vegetales y no proveniente de barras, gomitas, ni polvos?",
    option: [
      { key: "Sí", value: 8 },
      { key: "No", value: 0 },
      { key: "No sé", value: 0 },
    ],
  },
  {
    index: 14,
    title:
      "¿Consumió avena que no haya sido instantánea ni de cocimiento rápido?",
    option: [
      { key: "Sí", value: 4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 15,
    title:
      "¿Consumió cualquier tipo de fruta: fresca, congelada, enlatada en su propio jugo o seca y sin azúcar añadida?",
    option: [
      { key: "Sí", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 16,
    title:
      "¿Consumió cualquier tipo de bebida energética o refrescante endulzada (Aún si es con azúcar artificial)?",
    option: [
      { key: "Sí", value: -4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 17,
    title:
      "¿Consumió cualquier tipo de granos enteros? Por ejemplo: amaranto, cebada, arroz integral, trigo sarraceno (buckwheat), semolina gruesa, mijo, palomitas, quinua, centeno, sorgo, trigo de espelta, teff, trigo entero, arroz salvaje; ¿cualquiera de estos sin azúcar añadidatodo esto sin tocino, jamón u otra carne procesada?",
    option: [
      { key: "Sí", value: 8 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 18,
    title:
      "¿Consumió cualquier comida ultra procesada lista para calentar y servir que estuviera regularmente procesada de manera comercial, con preservatives añadidos, endulzantes, colorantes comestibles, saborizantes, estabilizadores y azúcares?",
    option: [
      { key: "Sí", value: -8 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 19,
    title: "¿Compró agua embotellada para consumo personal?",
    option: [
      { key: "Sí", value: -4 },
      { key: "No", value: 0 },
    ],
  },
  {
    index: 20,
    title:
      "¿Consumió cualquier tipo de prebiótico, incluyendo suero de leche, chícharos, kéfir, kimchi, kombucha, miso, natto, col agria, pan de masa madre, tempeh, yogur griego, yogur regular, yogur vegano (sin endulzar y sin tener azúcares o frutas añadidos)?",
    option: [
      { key: "Sí", value: 6 },
      { key: "No", value: 0 },
    ],
  },
  // {
  //   index: 21,
  //   title:
  //     "Daily, consume less than 6 teaspoonsof added sugar (female) and less than 9 teaspoons of added sugar(male)?",
  //   option: [
  //     { key: "Yes", value: 5 },
  //     { key: "No", value: -5 },
  //   ],
  // },
  // {
  //   index: 22,
  //   title:
  //     "Daily, consume less than 2,300mg sodium; if over 51, less than 1,500mg?",
  //   option: [
  //     { key: "Yes", value: 5 },
  //     { key: "No", value: -5 },
  //   ],
  // },
  // {
  //   index: 23,
  //   title:
  //     "Consume high fructose corn syrup (HFCS) or hydrogenated oil?",
  //   option: [
  //     { key: "Yes", value: 6 },
  //     { key: "No", value: 0 },
  //   ],
  // },
];
