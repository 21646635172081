import logo from './logo.svg';
import './App.css';
import MainPage from './mainPage';
import { BrowserRouter as Router, Route, Routes, Switch } from "react-router-dom";
// import { HashRouter as Router, Route, Routes, Switch } from "react-router-dom";

import Survey from './components/organisams/survey';
import Navbar from './components/molecules/navbar/navbar';
import ListOfPeople from './components/organisams/ListOfPeople/listOfPeople';
import LinkGenerator from './components/molecules/urlGenerator/urlGenerator';
import Dashboard from './dashboard/dashboard';
// window.localStorage.setItem("lang","English")
const App=()=> {
  return (
    <Router>
   
     <Routes>

     
     <Route exact path="/" element={<Survey/>} />
     <Route exact path="/listOfPeople" element={<ListOfPeople/>} />
     <Route exact path="/linkGenerator" element={<LinkGenerator/>} />
     <Route exact path="/dashboard" element={<Dashboard/>} />
     </Routes>
    
    </Router>
  );
}

export default App;
