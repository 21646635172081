import React, { useEffect, useState } from "react";
import "./table.css";

const Table = ({ list }) => {
  const [sharpPointAvg, setSharpPointAvg] = useState(0);
  const [riskPointAvg, setRiskPointAvg] = useState(0);
  const [missedPointAvg, setMissedPointAvg] = useState(0);
  const [questionArr, setquestionArr] = useState([]);
  useEffect(() => {
    let sharpPointTotal = 0,
      riskPointTotal = 0,
      missedPointTotal = 0;
    const totalItems = list.length;
    let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    list.forEach((item) => {
      sharpPointTotal = sharpPointTotal + item.smartPoints;
      riskPointTotal = riskPointTotal + item.riskPoints;
      missedPointTotal = missedPointTotal + item.missedPoints;
      item.submittedData.forEach((item2, index) => {
        if (item2 !==0) {
          // console.log("item2...",item2)
          arr[index] = arr[index] + 1;
        }
      });
    });
    // console.log(arr);
   let data =  arr.map((item)=>{
      return (item/totalItems*100).toFixed(2);
    })
    setquestionArr(data)

    setSharpPointAvg((sharpPointTotal / totalItems).toFixed(2));
    setRiskPointAvg((riskPointTotal / totalItems).toFixed(2));
    setMissedPointAvg((missedPointTotal / totalItems).toFixed(2));
  },[list]);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Group</th>
            <th>Vegan</th>
            <th>Vegetarian</th>
            <th>SHARP Points ({sharpPointAvg})</th>
            <th>Risk Points({riskPointAvg})</th>
            <th>Missed Points({missedPointAvg})</th>
            <th>Question Response

            <div className="response-container">
                      { questionArr && questionArr.map((item, index) => {
                        return (
                          <div>
                            {item < 0 || [1, 8, 15, 17, 18].includes(index) ? (
                              <p className="res-text neg">Q{index + 1}</p>
                            ) : item > 0 ? (
                              <p className="res-text pos">Q{index + 1}</p>
                            ) : (
                              <p className="res-text zero">Q{index + 1}</p>
                            )}
                           
                              <p className="res-text">Yes({item}%)</p>
                        
                          </div>
                        );
                      })}
                    </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item) => {
              return (
                <tr>
                  <td data-column="Name">{item.date ? item.date : ""}</td>
                  <td data-column="Name"  className={item.lang && item.lang==="Spanish"?"spanish":""}>{item.name}</td>
                  <td data-column="Email">{item.email}</td>
                  <td data-column="Group">{item.group ? item.group : ''}</td>
                  <td data-column="Vegan">
                    {item.isVegan === true ? "Yes" : "No"}
                  </td>
                  <td data-column="Vegan">
                    {item.isVegetarian
                      ? item.isVegetarian === true
                        ? "Yes"
                        : "No"
                      : ""}
                  </td>
                  <td data-column="Smart Points">{item.smartPoints}</td>
                  <td data-column="Smart Points">
                    {item.riskPoints ? item.riskPoints : ""}
                  </td>
                  <td data-column="Smart Points">
                    {item.missedPoints ? item.missedPoints : ""}
                  </td>
                  <td data-column="Question Response">
                    <div className="response-container">
                      {item.submittedData.map((item, index) => {
                        return (
                          <div>
                            {item < 0 || [1, 8, 15, 17, 18].includes(index) ? (
                              <p className="res-text neg">Q{index + 1}</p>
                            ) : item > 0 ? (
                              <p className="res-text pos">Q{index + 1}</p>
                            ) : (
                              <p className="res-text zero">Q{index + 1}</p>
                            )}
                            {item === 0 ? (
                              <p className="res-text">No</p>
                            ) : (
                              <p className="res-text">Yes</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
